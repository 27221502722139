import React from 'react';
import { Database, Link, Settings, ArrowRight } from 'lucide-react';

export default function Integrations() {
  const features = [
    {
      icon: Database,
      title: 'CRM Integration',
      description: 'Seamless connection with popular CRM systems for unified customer data.'
    },
    {
      icon: Link,
      title: 'API Access',
      description: 'Robust API for custom integrations and workflow automation.'
    },
    {
      icon: Settings,
      title: 'Custom Workflows',
      description: 'Create automated workflows tailored to your business processes.'
    }
  ];

  const integrations = [
    {
      name: 'Salesforce',
      logo: 'https://c1.sfdcstatic.com/content/dam/sfdc-docs/www/logos/logo-salesforce.svg'
    },
    {
      name: 'HubSpot',
      logo: 'https://www.hubspot.com/hubfs/HubSpot_Logos/HubSpot-Inversed-Favicon.png'
    },
    {
      name: 'Zendesk',
      logo: 'https://brandeps.com/logo-download/Z/Zendesk-logo-vector-01.svg'
    },
    {
      name: 'Microsoft Teams',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg'
    }
  ];

  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Powerful Integrations
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Connect your favorite tools and automate your workflow
          </p>
        </div>

        <div className="mt-16 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <div className="mt-6">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <div key={index} className="mt-10 first:mt-0">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                          <Icon className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          {feature.title}
                        </h3>
                        <p className="mt-2 text-base text-gray-500">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          
          <div className="mt-10 lg:mt-0">
            <div className="grid grid-cols-2 gap-8">
              {integrations.map((integration, index) => (
                <div key={index} className="flex items-center justify-center p-4 bg-white rounded-lg border border-gray-200">
                  <img
                    src={integration.logo}
                    alt={integration.name}
                    className="h-12 w-auto"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-16">
          <div className="bg-blue-50 rounded-2xl p-8">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">Ready to integrate?</h2>
              <p className="mt-4 text-gray-600">
                Connect your tools and streamline your workflow with our powerful integrations.
              </p>
              <button className="mt-8 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
                View All Integrations <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}