import React from 'react';
import { Shield, Users, Zap, Globe, ArrowRight } from 'lucide-react';

export default function Enterprise() {
  const features = [
    {
      icon: Shield,
      title: 'Enterprise Security',
      description: 'Advanced security features including SSO, audit logs, and custom data retention policies.'
    },
    {
      icon: Users,
      title: 'Dedicated Support',
      description: '24/7 priority support with dedicated account management team.'
    },
    {
      icon: Globe,
      title: 'Global Coverage',
      description: 'Multi-language support and international availability zones.'
    },
    {
      icon: Zap,
      title: 'Custom AI Training',
      description: 'Tailored AI models trained specifically for your business needs.'
    }
  ];

  const capabilities = [
    'Unlimited calls & messages',
    'Custom AI training',
    'Advanced security features',
    'Dedicated account team',
    'Custom integrations',
    'SLA guarantees',
    'Multi-language support',
    'Priority support',
    'Custom analytics',
    'API access'
  ];

  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Enterprise Solutions
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Custom AI reception solutions for large organizations
          </p>
        </div>

        <div className="mt-16 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <div className="mt-6">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <div key={index} className="mt-10 first:mt-0">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                          <Icon className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          {feature.title}
                        </h3>
                        <p className="mt-2 text-base text-gray-500">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          
          <div className="mt-10 lg:mt-0">
            <div className="bg-white rounded-2xl shadow-xl p-8 border-2 border-blue-600">
              <h2 className="text-2xl font-bold text-gray-900">Enterprise Features</h2>
              <ul className="mt-6 space-y-4">
                {capabilities.map((capability, index) => (
                  <li key={index} className="flex items-start">
                    <Shield className="h-5 w-5 text-blue-600 shrink-0 mt-1" />
                    <span className="ml-3 text-gray-600">{capability}</span>
                  </li>
                ))}
              </ul>
              <div className="mt-8">
                <button className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
                  Contact Sales <ArrowRight className="ml-2 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-16">
          <div className="bg-blue-50 rounded-2xl p-8">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">Ready for enterprise-grade service?</h2>
              <p className="mt-4 text-gray-600">
                Let's discuss your specific needs and create a custom solution for your organization.
              </p>
              <div className="mt-8 flex justify-center space-x-4">
                <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
                  Schedule Consultation <ArrowRight className="ml-2 h-5 w-5" />
                </button>
                <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-blue-600 bg-white hover:bg-gray-50">
                  Download Whitepaper
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}