import React from 'react';
import { Calendar, User, ArrowRight } from 'lucide-react';

const blogPosts = [
  {
    title: 'The Future of AI in Business Communication',
    excerpt: 'Explore how artificial intelligence is transforming the way businesses interact with customers.',
    author: 'Sarah Johnson',
    date: 'Mar 15, 2024',
    image: 'https://images.unsplash.com/photo-1485827404703-89b55fcc595e?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    category: 'Technology'
  },
  {
    title: 'Maximizing Efficiency with AI Receptionists',
    excerpt: 'Learn how AI-powered reception services can streamline your business operations.',
    author: 'Michael Chen',
    date: 'Mar 10, 2024',
    image: 'https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    category: 'Business'
  },
  {
    title: 'The Evolution of Customer Service',
    excerpt: 'Discover how AI is revolutionizing customer service and improving satisfaction rates.',
    author: 'Emily Rodriguez',
    date: 'Mar 5, 2024',
    image: 'https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    category: 'Customer Service'
  }
];

const categories = [
  'All',
  'Technology',
  'Business',
  'Customer Service',
  'AI Innovation',
  'Case Studies'
];

export default function Blog() {
  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Latest Updates
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Insights and news about AI technology and business communication
          </p>
        </div>

        {/* Categories */}
        <div className="mt-12">
          <div className="flex flex-wrap justify-center gap-4">
            {categories.map((category, index) => (
              <button
                key={index}
                className={`px-4 py-2 rounded-full text-sm font-medium ${
                  index === 0
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {/* Featured Post */}
        <div className="mt-12">
          <div className="relative rounded-2xl overflow-hidden">
            <img
              src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
              alt="Featured post"
              className="w-full h-96 object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent">
              <div className="absolute bottom-0 p-8">
                <span className="text-blue-400 text-sm font-semibold">FEATURED</span>
                <h2 className="mt-2 text-3xl font-bold text-white">
                  The Impact of AI on Modern Business Communication
                </h2>
                <p className="mt-4 text-gray-300 max-w-2xl">
                  Discover how AI is revolutionizing the way businesses communicate with their customers
                  and transforming customer service.
                </p>
                <div className="mt-6 flex items-center">
                  <img
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=40&h=40&q=80"
                    alt="Author"
                    className="h-10 w-10 rounded-full"
                  />
                  <div className="ml-4">
                    <p className="text-sm font-medium text-white">John Doe</p>
                    <div className="flex space-x-4 text-sm text-gray-300">
                      <span>Mar 20, 2024</span>
                      <span>·</span>
                      <span>10 min read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Blog Posts Grid */}
        <div className="mt-12 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {blogPosts.map((post, index) => (
            <article key={index} className="bg-white rounded-2xl shadow-sm overflow-hidden">
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <span className="text-blue-600 text-sm font-semibold">{post.category}</span>
                <h3 className="mt-2 text-xl font-bold text-gray-900">
                  {post.title}
                </h3>
                <p className="mt-3 text-gray-500">
                  {post.excerpt}
                </p>
                <div className="mt-6 flex items-center">
                  <div className="flex-1">
                    <div className="flex items-center">
                      <User className="h-5 w-5 text-gray-400" />
                      <span className="ml-2 text-sm text-gray-600">{post.author}</span>
                    </div>
                    <div className="mt-1 flex items-center">
                      <Calendar className="h-5 w-5 text-gray-400" />
                      <span className="ml-2 text-sm text-gray-600">{post.date}</span>
                    </div>
                  </div>
                  <button className="flex items-center text-blue-600 hover:text-blue-700">
                    Read more <ArrowRight className="ml-2 h-4 w-4" />
                  </button>
                </div>
              </div>
            </article>
          ))}
        </div>

        {/* Newsletter */}
        <div className="mt-16">
          <div className="bg-blue-50 rounded-2xl p-8">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">Stay Updated</h2>
              <p className="mt-4 text-gray-600">
                Get the latest insights about AI technology and business communication.
              </p>
              <form className="mt-8 sm:flex sm:max-w-md sm:mx-auto">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="block w-full px-5 py-3 text-base text-gray-900 placeholder-gray-500 border border-gray-300 rounded-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:flex-1"
                />
                <button
                  type="submit"
                  className="mt-4 sm:mt-0 sm:ml-3 block w-full px-5 py-3 text-base font-medium text-white bg-blue-600 border border-transparent rounded-full shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:px-10"
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}