import React from 'react';
import { Check, X } from 'lucide-react';

const plans = [
  {
    name: 'Basic',
    price: 99,
    description: 'Perfect for small businesses just getting started',
    features: [
      { name: 'Up to 100 calls per month', included: true },
      { name: 'Business hours coverage (9am-6pm)', included: true },
      { name: 'Basic call screening', included: true },
      { name: 'Message taking', included: true },
      { name: 'Email notifications', included: true },
      { name: 'Basic reporting', included: true },
      { name: '24/7 coverage', included: false },
      { name: 'Custom greeting', included: false },
      { name: 'CRM integration', included: false },
      { name: 'Multi-language support', included: false }
    ]
  },
  {
    name: 'Professional',
    price: 199,
    description: 'Ideal for growing businesses needing full coverage',
    popular: true,
    features: [
      { name: 'Up to 500 calls per month', included: true },
      { name: '24/7 coverage', included: true },
      { name: 'Advanced call screening', included: true },
      { name: 'Message taking', included: true },
      { name: 'SMS & email notifications', included: true },
      { name: 'Advanced analytics', included: true },
      { name: 'Custom greeting', included: true },
      { name: 'CRM integration', included: true },
      { name: 'Multi-language support', included: true },
      { name: 'Priority support', included: false }
    ]
  },
  {
    name: 'Enterprise',
    price: 399,
    description: 'For businesses requiring comprehensive solutions',
    features: [
      { name: 'Unlimited calls', included: true },
      { name: '24/7 coverage', included: true },
      { name: 'Advanced call screening', included: true },
      { name: 'Message taking', included: true },
      { name: 'SMS & email notifications', included: true },
      { name: 'Custom analytics', included: true },
      { name: 'Custom greeting', included: true },
      { name: 'Advanced CRM integration', included: true },
      { name: 'Multi-language support', included: true },
      { name: 'Priority support', included: true }
    ]
  }
];

export default function Pricing() {
  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Simple, Transparent Pricing
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Choose the perfect plan for your business needs. All plans include our core AI technology.
          </p>
        </div>

        <div className="mt-16 grid grid-cols-1 gap-6 lg:grid-cols-3">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`relative rounded-2xl ${
                plan.popular
                  ? 'border-2 border-blue-600 shadow-xl scale-105'
                  : 'border border-gray-200'
              } bg-white p-8 shadow-sm flex flex-col`}
            >
              {plan.popular && (
                <div className="absolute top-0 right-6 -translate-y-1/2">
                  <span className="inline-flex rounded-full bg-blue-600 px-4 py-1 text-sm font-semibold text-white">
                    Most Popular
                  </span>
                </div>
              )}

              <div className="mb-8">
                <h3 className="text-2xl font-semibold text-gray-900">{plan.name}</h3>
                <p className="mt-2 text-gray-500">{plan.description}</p>
                <div className="mt-4 flex items-baseline">
                  <span className="text-4xl font-extrabold text-gray-900">${plan.price}</span>
                  <span className="ml-2 text-gray-500">/month</span>
                </div>
              </div>

              <ul className="space-y-4 flex-grow mb-8">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-start">
                    {feature.included ? (
                      <Check className="h-5 w-5 text-blue-600 shrink-0" />
                    ) : (
                      <X className="h-5 w-5 text-gray-300 shrink-0" />
                    )}
                    <span className={`ml-3 ${feature.included ? 'text-gray-600' : 'text-gray-400'}`}>
                      {feature.name}
                    </span>
                  </li>
                ))}
              </ul>

              <button
                className={`w-full rounded-full px-6 py-3 text-center text-sm font-semibold ${
                  plan.popular
                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                    : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
                } transition-colors duration-200`}
              >
                Get started
              </button>
            </div>
          ))}
        </div>

        <div className="mt-16 bg-blue-50 rounded-2xl p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Need a custom plan?</h2>
            <p className="mt-2 text-gray-600">
              Contact us for custom pricing and features tailored to your specific needs.
            </p>
            <button className="mt-6 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
              Contact Sales
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}