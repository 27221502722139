import React from 'react';
import { Users, Target, Award, Heart, ArrowRight } from 'lucide-react';

const values = [
  {
    icon: Users,
    title: 'Customer First',
    description: 'We prioritize exceptional customer service through advanced AI technology.'
  },
  {
    icon: Target,
    title: 'Innovation',
    description: 'Continuously improving our AI technology for better service delivery.'
  },
  {
    icon: Award,
    title: 'Excellence',
    description: 'Maintaining the highest standards in AI-powered communication.'
  },
  {
    icon: Heart,
    title: 'Empathy',
    description: 'Programming our AI to understand and address customer needs with care.'
  }
];

const milestones = [
  {
    year: '2024',
    title: 'Launch of AI Receptionist Agency',
    description: 'Revolutionizing business communication with advanced AI technology.'
  },
  {
    year: '2024',
    title: 'Multi-language Support',
    description: 'Expanding our AI capabilities to support global businesses.'
  },
  {
    year: '2024',
    title: 'Advanced Integration Platform',
    description: 'Connecting with major CRM and business tools.'
  }
];

export default function About() {
  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            About AI Receptionist Agency
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Transforming business communication through artificial intelligence
          </p>
        </div>

        {/* Mission Section */}
        <div className="mt-16 lg:grid lg:grid-cols-2 lg:gap-16 items-center">
          <div>
            <h2 className="text-3xl font-bold text-gray-900">Our Mission</h2>
            <p className="mt-4 text-lg text-gray-500">
              We're on a mission to revolutionize how businesses handle customer communications using advanced AI technology. Our goal is to provide 24/7 professional reception services that enhance customer experience while reducing operational costs.
            </p>
            <div className="mt-8">
              <a href="/contact" className="inline-flex items-center text-blue-600 hover:text-blue-700">
                Join our mission <ArrowRight className="ml-2 h-5 w-5" />
              </a>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <img
              className="rounded-2xl shadow-lg"
              src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
              alt="Team collaboration"
            />
          </div>
        </div>

        {/* Values Section */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-gray-900 text-center">Our Values</h2>
          <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {values.map((value, index) => {
              const Icon = value.icon;
              return (
                <div key={index} className="text-center">
                  <div className="mx-auto h-16 w-16 bg-blue-100 rounded-full flex items-center justify-center">
                    <Icon className="h-8 w-8 text-blue-600" />
                  </div>
                  <h3 className="mt-4 text-xl font-semibold text-gray-900">{value.title}</h3>
                  <p className="mt-2 text-gray-500">{value.description}</p>
                </div>
              );
            })}
          </div>
        </div>

        {/* Timeline Section */}
        <div className="mt-24">
          <h2 className="text-3xl font-bold text-gray-900 text-center">Our Journey</h2>
          <div className="mt-12 relative">
            <div className="absolute top-0 left-1/2 w-0.5 h-full bg-gray-200 -ml-px"></div>
            <div className="space-y-16">
              {milestones.map((milestone, index) => (
                <div key={index} className={`relative ${index % 2 === 0 ? 'lg:flex-row-reverse' : ''}`}>
                  <div className="flex items-center">
                    <div className="flex-1 lg:w-1/2">
                      <div className={`lg:pr-8 ${index % 2 === 0 ? 'lg:text-right' : ''}`}>
                        <span className="text-blue-600 font-semibold">{milestone.year}</span>
                        <h3 className="mt-2 text-xl font-bold text-gray-900">{milestone.title}</h3>
                        <p className="mt-2 text-gray-500">{milestone.description}</p>
                      </div>
                    </div>
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-600 rounded-full border-4 border-white relative z-10"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="mt-24">
          <div className="bg-blue-50 rounded-2xl p-8">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">Join Our Team</h2>
              <p className="mt-4 text-gray-600">
                Help us shape the future of business communication with AI technology.
              </p>
              <div className="mt-8 flex justify-center space-x-4">
                <a
                  href="/careers"
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700"
                >
                  View Open Positions <ArrowRight className="ml-2 h-5 w-5" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}