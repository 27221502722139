import React from 'react';
import { Phone, MessageSquare, Calendar, Brain, Shield, Users } from 'lucide-react';

const services = [
  {
    icon: Phone,
    title: 'Live Receptionist Services',
    description: 'Our AI receptionists handle your calls 24/7, ensuring every customer interaction is professional and efficient.',
    features: [
      'Call screening and routing',
      'Message taking',
      'Appointment scheduling',
      'Emergency call handling',
      'Multi-language support',
      'Custom greetings'
    ]
  },
  {
    icon: MessageSquare,
    title: 'Chat & SMS Support',
    description: 'Engage with customers through their preferred communication channels with our intelligent chat and SMS solutions.',
    features: [
      'Website live chat',
      'SMS response handling',
      'Automated follow-ups',
      'Lead qualification',
      'Integration with CRM',
      'Analytics and reporting'
    ]
  },
  {
    icon: Calendar,
    title: 'Appointment Scheduling',
    description: 'Streamline your booking process with our intelligent scheduling system.',
    features: [
      'Online booking portal',
      'Calendar integration',
      'Automated reminders',
      'Resource management',
      'Custom availability rules',
      'Client self-scheduling'
    ]
  }
];

export default function Services() {
  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Our Services
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Comprehensive communication solutions powered by advanced AI technology
          </p>
        </div>

        <div className="mt-16 space-y-16">
          {services.map((service, index) => {
            const Icon = service.icon;
            return (
              <div key={index} className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className={index % 2 === 0 ? '' : 'lg:order-2'}>
                  <div className="relative">
                    <div className="aspect-w-16 aspect-h-9">
                      <div className="w-full h-full bg-blue-100 rounded-2xl flex items-center justify-center">
                        <Icon className="h-24 w-24 text-blue-600" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={index % 2 === 0 ? 'mt-8 lg:mt-0' : 'mt-8 lg:mt-0 lg:order-1'}>
                  <h2 className="text-3xl font-extrabold text-gray-900">
                    {service.title}
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    {service.description}
                  </p>
                  <ul className="mt-8 space-y-4">
                    {service.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-center">
                        <div className="flex-shrink-0">
                          <Shield className="h-5 w-5 text-blue-600" />
                        </div>
                        <span className="ml-3 text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}