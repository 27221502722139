import React from 'react';
import { Calendar, MessageCircle, Clock, Brain, Shield, BarChart, Phone, Globe2, Zap, Users, FileText, Headphones } from 'lucide-react';

const features = [
  {
    icon: Phone,
    title: 'Live Call Answering',
    description: 'Professional 24/7 call handling with AI-powered natural conversations.',
  },
  {
    icon: Calendar,
    title: 'Smart Scheduling',
    description: 'Automated appointment booking and calendar management with seamless integration.',
  },
  {
    icon: MessageCircle,
    title: 'Lead Screening',
    description: 'Qualify leads and capture important information before transfer.',
  },
  {
    icon: Globe2,
    title: 'Multi-language Support',
    description: 'Support customers in multiple languages with natural translations.',
  },
  {
    icon: Zap,
    title: 'Instant Response',
    description: 'Zero wait times with immediate call answering and processing.',
  },
  {
    icon: Users,
    title: 'Team Collaboration',
    description: 'Seamless message delivery and team coordination.',
  },
  {
    icon: FileText,
    title: 'Custom Scripts',
    description: 'Tailored call scripts and responses for your business needs.',
  },
  {
    icon: Brain,
    title: 'AI Learning',
    description: 'Continuous improvement through machine learning and adaptation.',
  },
  {
    icon: Shield,
    title: 'Secure & Compliant',
    description: 'Enterprise-grade security with HIPAA and GDPR compliance.',
  },
  {
    icon: BarChart,
    title: 'Advanced Analytics',
    description: 'Detailed insights and reporting on all communications.',
  },
  {
    icon: Headphones,
    title: 'Priority Support',
    description: '24/7 technical support and dedicated account management.',
  },
  {
    icon: Clock,
    title: 'Always Available',
    description: 'Round-the-clock service ensuring you never miss an opportunity.',
  },
];

export default function Features() {
  return (
    <div id="features" className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Comprehensive Features for Modern Businesses
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Everything you need to manage your communications and grow your business
          </p>
        </div>

        <div className="mt-16">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className="relative group">
                  <div className="h-full flex flex-col bg-white p-8 border border-gray-200 rounded-2xl transition-all duration-300 hover:shadow-lg hover:scale-105">
                    <div className="flex items-center justify-center h-12 w-12 rounded-xl bg-blue-100 text-blue-600 mb-4">
                      <Icon className="h-6 w-6" />
                    </div>
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">{feature.title}</h3>
                    <p className="text-gray-500 flex-grow">{feature.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}