import React from 'react';
import { Briefcase, Building2, Stethoscope, Scale, Wrench, GraduationCap, ShoppingBag, Plane } from 'lucide-react';

const industries = [
  {
    icon: Briefcase,
    title: 'Professional Services',
    description: 'Perfect for law firms, consultants, and financial advisors who need professional call handling and client management.',
    benefits: [
      'Custom call scripts for your industry',
      'Professional client intake',
      'Appointment scheduling',
      'Emergency call handling',
      'Secure message delivery'
    ]
  },
  {
    icon: Building2,
    title: 'Real Estate',
    description: 'Ideal for real estate agents and property managers who need to manage inquiries and showings efficiently.',
    benefits: [
      'Property information handling',
      'Showing scheduling',
      'Lead qualification',
      'After-hours support',
      'Emergency maintenance calls'
    ]
  },
  {
    icon: Stethoscope,
    title: 'Healthcare',
    description: 'HIPAA-compliant solutions for medical practices, clinics, and healthcare providers.',
    benefits: [
      'HIPAA compliance',
      'Patient appointment scheduling',
      'Emergency triage',
      'Insurance verification',
      'Secure message handling'
    ]
  },
  {
    icon: Scale,
    title: 'Legal',
    description: 'Specialized solutions for law firms requiring detailed client intake and urgent call management.',
    benefits: [
      'Legal intake forms',
      'Conflict checking',
      'Urgent call routing',
      'Client qualification',
      'Secure messaging'
    ]
  }
];

export default function Industries() {
  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Industries We Serve
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Tailored AI receptionist solutions for your specific industry needs
          </p>
        </div>

        <div className="mt-16 grid grid-cols-1 gap-12 lg:grid-cols-2">
          {industries.map((industry, index) => {
            const Icon = industry.icon;
            return (
              <div key={index} className="bg-white rounded-2xl shadow-sm border border-gray-200 p-8">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="h-16 w-16 bg-blue-100 rounded-xl flex items-center justify-center">
                      <Icon className="h-8 w-8 text-blue-600" />
                    </div>
                  </div>
                  <div className="ml-6">
                    <h2 className="text-2xl font-bold text-gray-900">{industry.title}</h2>
                  </div>
                </div>
                <p className="mt-4 text-gray-500">{industry.description}</p>
                <ul className="mt-8 space-y-4">
                  {industry.benefits.map((benefit, benefitIndex) => (
                    <li key={benefitIndex} className="flex items-center">
                      <div className="flex-shrink-0">
                        <div className="h-5 w-5 rounded-full bg-blue-50 flex items-center justify-center">
                          <div className="h-2 w-2 rounded-full bg-blue-600"></div>
                        </div>
                      </div>
                      <span className="ml-3 text-gray-600">{benefit}</span>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}