import React from 'react';
import { MessageSquare, Clock, CheckCircle, ArrowRight } from 'lucide-react';

export default function Hero() {
  return (
    <div className="pt-24 pb-16 bg-gradient-to-br from-primary-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <div className="inline-flex items-center px-4 py-1.5 rounded-full bg-primary-100 mb-6">
              <span className="text-sm font-medium text-primary-800">New: Multi-language Support</span>
            </div>
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block">24/7 Live</span>
              <span className="block text-primary-600">Virtual Receptionists</span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              Never miss a call again. Our AI receptionists handle your calls 24/7, schedule appointments, and manage customer inquiries with human-like interaction.
            </p>
            
            <div className="mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a href="#contact" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-full text-white bg-primary-600 hover:bg-primary-700 md:py-4 md:text-lg md:px-10">
                  Start Free Trial <ArrowRight className="ml-2 h-5 w-5" />
                </a>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <a href="#pricing" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-full text-primary-600 bg-white hover:bg-gray-50 border-primary-600 md:py-4 md:text-lg md:px-10">
                  View Pricing
                </a>
              </div>
            </div>

            <div className="mt-8 grid grid-cols-3 gap-4">
              <div className="flex items-center">
                <MessageSquare className="h-5 w-5 text-primary-600" />
                <span className="ml-2 text-sm text-gray-500">24/7 Support</span>
              </div>
              <div className="flex items-center">
                <Clock className="h-5 w-5 text-primary-600" />
                <span className="ml-2 text-sm text-gray-500">Instant Setup</span>
              </div>
              <div className="flex items-center">
                <CheckCircle className="h-5 w-5 text-primary-600" />
                <span className="ml-2 text-sm text-gray-500">No Contract</span>
              </div>
            </div>
          </div>
          
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
              <img
                className="w-full rounded-lg"
                src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
                alt="AI Receptionist at work"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent rounded-lg flex items-end">
                <div className="p-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80" alt="Customer" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-white">
                        "The best decision for our business"
                      </p>
                      <p className="text-xs text-gray-300">
                        Sarah J. - CEO at TechCorp
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}