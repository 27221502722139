import React from 'react';

export default function Terms() {
  return (
    <div className="py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-gray-900">Terms of Service</h1>
        <div className="mt-8 prose prose-blue">
          <p className="text-lg text-gray-500">Last updated: {new Date().toLocaleDateString()}</p>

          <h2 className="text-2xl font-bold mt-8">1. Acceptance of Terms</h2>
          <p>
            By accessing and using our services, you agree to be bound by these Terms of Service and all applicable laws and regulations.
          </p>

          <h2 className="text-2xl font-bold mt-8">2. Use of Services</h2>
          <p>You agree to:</p>
          <ul>
            <li>Provide accurate information</li>
            <li>Maintain the security of your account</li>
            <li>Use services in compliance with laws</li>
            <li>Not misuse or abuse our services</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8">3. Service Availability</h2>
          <p>
            We strive to maintain 99.9% uptime but cannot guarantee uninterrupted access to our services.
          </p>

          <h2 className="text-2xl font-bold mt-8">4. Payment Terms</h2>
          <p>
            Subscription fees are billed in advance on a monthly or annual basis. Refunds are handled according to our refund policy.
          </p>

          <h2 className="text-2xl font-bold mt-8">5. Termination</h2>
          <p>
            We reserve the right to terminate or suspend access to our services for violations of these terms.
          </p>

          <h2 className="text-2xl font-bold mt-8">6. Contact</h2>
          <p>
            For questions about these Terms, please contact us at:
            <br />
            <a href="mailto:legal@aireceptionist.com" className="text-blue-600 hover:text-blue-800">
              legal@aireceptionist.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}