import React from 'react';
import { MessageSquare, Zap, Shield, ArrowRight } from 'lucide-react';

export default function Messaging() {
  const features = [
    {
      icon: MessageSquare,
      title: 'Intelligent Messaging',
      description: 'AI-powered messaging that understands context and responds appropriately.'
    },
    {
      icon: Zap,
      title: 'Instant Response',
      description: 'Real-time message handling with zero wait times.'
    },
    {
      icon: Shield,
      title: 'Secure Communication',
      description: 'End-to-end encryption and secure message handling.'
    }
  ];

  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            AI Message Management
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Smart message handling and routing powered by AI
          </p>
        </div>

        <div className="mt-16 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <div className="mt-6">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <div key={index} className="mt-10 first:mt-0">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                          <Icon className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          {feature.title}
                        </h3>
                        <p className="mt-2 text-base text-gray-500">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          
          <div className="mt-10 lg:mt-0">
            <div className="aspect-w-16 aspect-h-9">
              <img
                className="rounded-lg shadow-lg object-cover"
                src="https://images.unsplash.com/photo-1577563908411-5077b6dc7624?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
                alt="Message Management"
              />
            </div>
          </div>
        </div>

        <div className="mt-16">
          <div className="bg-blue-50 rounded-2xl p-8">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">Never miss a message</h2>
              <p className="mt-4 text-gray-600">
                Let our AI handle your messages while maintaining a personal touch.
              </p>
              <button className="mt-8 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
                Get Started <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}