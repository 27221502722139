import React from 'react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import UseCases from '../components/UseCases';
import Integrations from '../components/Integrations';
import Testimonials from '../components/Testimonials';

export default function Home() {
  return (
    <>
      <Hero />
      <Features />
      <UseCases />
      <Integrations />
      <Testimonials />
    </>
  );
}