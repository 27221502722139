import React from 'react';
import { Calendar, MessageSquare, Phone, Database, ArrowRight } from 'lucide-react';

const integrations = [
  {
    name: 'Google Calendar',
    logo: 'https://www.gstatic.com/images/branding/product/2x/calendar_48dp.png',
    description: 'Seamless calendar integration for appointment scheduling',
  },
  {
    name: 'Salesforce',
    logo: 'https://c1.sfdcstatic.com/content/dam/sfdc-docs/www/logos/logo-salesforce.svg',
    description: 'Automatic CRM updates and lead management',
  },
  {
    name: 'Zoom',
    logo: 'https://st1.zoom.us/zoom.ico',
    description: 'Video conferencing integration for virtual meetings',
  },
  {
    name: 'Slack',
    logo: 'https://a.slack-edge.com/80588/marketing/img/meta/slack_hash_256.png',
    description: 'Real-time notifications and team collaboration',
  },
];

export default function Integrations() {
  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Seamless Integrations
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Connect with your favorite tools and platforms
          </p>
        </div>

        <div className="mt-16 grid grid-cols-2 gap-8 md:grid-cols-4">
          {integrations.map((integration, index) => (
            <div key={index} className="flex flex-col items-center">
              <img
                src={integration.logo}
                alt={integration.name}
                className="h-16 w-16 object-contain"
              />
              <h3 className="mt-4 text-lg font-medium text-gray-900">{integration.name}</h3>
              <p className="mt-2 text-sm text-gray-500 text-center">{integration.description}</p>
            </div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <a
            href="#contact"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-blue-600 bg-blue-50 hover:bg-blue-100"
          >
            View all integrations <ArrowRight className="ml-2 h-5 w-5" />
          </a>
        </div>
      </div>
    </div>
  );
}