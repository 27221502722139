import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

// Main pages
import Home from './pages/Home';
import Services from './pages/Services';
import Industries from './pages/Industries';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';

// Feature pages
import CallAnswering from './pages/features/CallAnswering';
import Scheduling from './pages/features/Scheduling';
import LeadQualification from './pages/features/LeadQualification';
import Messaging from './pages/features/Messaging';
import Integrations from './pages/features/Integrations';
import Analytics from './pages/features/Analytics';
import VoiceAssistant from './pages/features/VoiceAssistant';
import Outreach from './pages/features/Outreach';

// Industry pages
import Legal from './pages/industries/Legal';
import HomeServices from './pages/industries/HomeServices';
import Software from './pages/industries/Software';
import Marketing from './pages/industries/Marketing';
import Medical from './pages/industries/Medical';

// Company size pages
import Solo from './pages/companies/Solo';
import Small from './pages/companies/Small';
import MidMarket from './pages/companies/MidMarket';
import Enterprise from './pages/companies/Enterprise';

// Integration pages
import SalesforceIntegration from './pages/integrations/Salesforce';
import HubspotIntegration from './pages/integrations/Hubspot';
import ZapierIntegration from './pages/integrations/Zapier';
import SlackIntegration from './pages/integrations/Slack';

// Company pages
import About from './pages/company/About';
import Blog from './pages/company/Blog';
import Press from './pages/company/Press';
import Feedback from './pages/company/Feedback';
import Help from './pages/company/Help';

// Pricing pages
import EnterprisePricing from './pages/pricing/Enterprise';
import ComparePlans from './pages/pricing/Compare';

// Legal pages
import Privacy from './pages/legal/Privacy';
import Terms from './pages/legal/Terms';
import Cookies from './pages/legal/Cookies';
import GDPR from './pages/legal/GDPR';

function App() {
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Navbar />
      <main className="flex-grow">
        <Routes>
          {/* Main routes */}
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          
          {/* Feature routes */}
          <Route path="/features">
            <Route path="call-answering" element={<CallAnswering />} />
            <Route path="scheduling" element={<Scheduling />} />
            <Route path="lead-qualification" element={<LeadQualification />} />
            <Route path="messaging" element={<Messaging />} />
            <Route path="integrations" element={<Integrations />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="voice-assistant" element={<VoiceAssistant />} />
            <Route path="outreach" element={<Outreach />} />
          </Route>

          {/* Industry routes */}
          <Route path="/industries">
            <Route path="legal" element={<Legal />} />
            <Route path="home-services" element={<HomeServices />} />
            <Route path="software" element={<Software />} />
            <Route path="marketing" element={<Marketing />} />
            <Route path="medical" element={<Medical />} />
          </Route>

          {/* Company size routes */}
          <Route path="/companies">
            <Route path="solo" element={<Solo />} />
            <Route path="small" element={<Small />} />
            <Route path="mid-market" element={<MidMarket />} />
            <Route path="enterprise" element={<Enterprise />} />
          </Route>

          {/* Integration routes */}
          <Route path="/integrations">
            <Route path="salesforce" element={<SalesforceIntegration />} />
            <Route path="hubspot" element={<HubspotIntegration />} />
            <Route path="zapier" element={<ZapierIntegration />} />
            <Route path="slack" element={<SlackIntegration />} />
          </Route>

          {/* Company routes */}
          <Route path="/company">
            <Route path="about" element={<About />} />
            <Route path="blog" element={<Blog />} />
            <Route path="press" element={<Press />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="help" element={<Help />} />
          </Route>

          {/* Pricing routes */}
          <Route path="/pricing">
            <Route path="enterprise" element={<EnterprisePricing />} />
            <Route path="compare" element={<ComparePlans />} />
          </Route>

          {/* Legal routes */}
          <Route path="/legal">
            <Route path="privacy" element={<Privacy />} />
            <Route path="terms" element={<Terms />} />
            <Route path="cookies" element={<Cookies />} />
            <Route path="gdpr" element={<GDPR />} />
          </Route>

          {/* Catch-all route - redirect to home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;