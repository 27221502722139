import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu as MenuIcon, X, Phone, ChevronDown } from 'lucide-react';
import { Menu, Transition } from '@headlessui/react';

const products = [
  {
    name: 'AI Virtual Receptionists',
    description: 'AI-powered answering, intake & scheduling',
    href: '/features/call-answering'
  },
  {
    name: 'Voice Assistant',
    description: 'AI-first answering & intake',
    href: '/features/voice-assistant',
    badge: 'New'
  },
  {
    name: 'Outreach Campaigns',
    description: 'Outbound sales, service & support',
    href: '/features/outreach'
  },
  {
    name: 'AI Powered Web Chat',
    description: 'AI + chat, SMS & social messaging',
    href: '/features/messaging'
  }
];

const solutions = [
  {
    name: 'Industries',
    items: [
      { name: 'Law firms', href: '/industries/legal' },
      { name: 'Home services', href: '/industries/home-services' },
      { name: 'Software & SaaS', href: '/industries/software' },
      { name: 'Marketing agencies', href: '/industries/marketing' },
      { name: 'Medical & wellness', href: '/industries/medical' }
    ]
  },
  {
    name: 'Companies',
    items: [
      { name: 'Solo business', href: '/companies/solo' },
      { name: 'Small business', href: '/companies/small' },
      { name: 'Mid-market', href: '/companies/mid-market' },
      { name: 'Enterprise', href: '/companies/enterprise' }
    ]
  },
  {
    name: 'Integrations',
    items: [
      { name: 'Salesforce', href: '/integrations/salesforce' },
      { name: 'HubSpot', href: '/integrations/hubspot' },
      { name: 'Zapier', href: '/integrations/zapier' },
      { name: 'Slack', href: '/integrations/slack' }
    ]
  }
];

const pricing = [
  { name: 'Plans & Pricing', href: '/pricing' },
  { name: 'Enterprise', href: '/pricing/enterprise' },
  { name: 'Compare Plans', href: '/pricing/compare' }
];

const company = [
  { name: 'About us', href: '/company/about' },
  { name: 'Blog', href: '/company/blog' },
  { name: 'Press releases', href: '/company/press' },
  { name: 'Share feedback', href: '/company/feedback' },
  { name: 'Help center', href: '/company/help' }
];

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="fixed w-full bg-white/95 backdrop-blur-sm z-50 border-b">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <Phone className="h-8 w-8 text-primary-600" />
              <span className="ml-2 text-xl font-bold text-gray-900">AI Receptionist Agency</span>
            </Link>
          </div>

          <div className="hidden md:flex items-center space-x-1">
            <Menu as="div" className="relative">
              {({ open }) => (
                <>
                  <Menu.Button className="px-3 py-2 text-gray-600 hover:text-primary-600 inline-flex items-center">
                    Products
                    <ChevronDown className={`ml-1 h-4 w-4 transform ${open ? 'rotate-180' : ''}`} />
                  </Menu.Button>
                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Menu.Items className="absolute left-0 mt-2 w-80 origin-top-left bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="p-4 space-y-3">
                        {products.map((product) => (
                          <Menu.Item key={product.name}>
                            <Link
                              to={product.href}
                              className="flex items-start p-3 rounded-lg hover:bg-gray-50"
                            >
                              <div>
                                <p className="text-sm font-medium text-gray-900">{product.name}</p>
                                <p className="text-sm text-gray-500">{product.description}</p>
                              </div>
                            </Link>
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>

            <Menu as="div" className="relative">
              {({ open }) => (
                <>
                  <Menu.Button className="px-3 py-2 text-gray-600 hover:text-primary-600 inline-flex items-center">
                    Solutions
                    <ChevronDown className={`ml-1 h-4 w-4 transform ${open ? 'rotate-180' : ''}`} />
                  </Menu.Button>
                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Menu.Items className="absolute left-0 mt-2 w-96 origin-top-left bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="p-4 grid grid-cols-3 gap-4">
                        {solutions.map((section) => (
                          <div key={section.name}>
                            <h3 className="text-sm font-semibold text-gray-900 mb-2">{section.name}</h3>
                            <ul className="space-y-2">
                              {section.items.map((item) => (
                                <Menu.Item key={item.name}>
                                  <Link
                                    to={item.href}
                                    className="block text-sm text-gray-500 hover:text-primary-600"
                                  >
                                    {item.name}
                                  </Link>
                                </Menu.Item>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>

            <Menu as="div" className="relative">
              {({ open }) => (
                <>
                  <Menu.Button className="px-3 py-2 text-gray-600 hover:text-primary-600 inline-flex items-center">
                    Pricing
                    <ChevronDown className={`ml-1 h-4 w-4 transform ${open ? 'rotate-180' : ''}`} />
                  </Menu.Button>
                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Menu.Items className="absolute left-0 mt-2 w-48 origin-top-left bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="p-2">
                        {pricing.map((item) => (
                          <Menu.Item key={item.name}>
                            <Link
                              to={item.href}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg"
                            >
                              {item.name}
                            </Link>
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>

            <Menu as="div" className="relative">
              {({ open }) => (
                <>
                  <Menu.Button className="px-3 py-2 text-gray-600 hover:text-primary-600 inline-flex items-center">
                    Company
                    <ChevronDown className={`ml-1 h-4 w-4 transform ${open ? 'rotate-180' : ''}`} />
                  </Menu.Button>
                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="p-2">
                        {company.map((item) => (
                          <Menu.Item key={item.name}>
                            <Link
                              to={item.href}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg"
                            >
                              {item.name}
                            </Link>
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>

            <Link
              to="/contact"
              className="ml-8 bg-primary-600 text-white px-6 py-2 rounded-full hover:bg-primary-700 transition-colors"
            >
              Get Started
            </Link>
          </div>

          <div className="md:hidden">
            <button onClick={() => setIsOpen(!isOpen)} className="text-gray-600">
              {isOpen ? <X className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white border-b">
            {[...products, ...solutions.flatMap(s => s.items), ...pricing, ...company].map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="block px-3 py-2 text-gray-600 hover:text-primary-600"
                onClick={() => setIsOpen(false)}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
}