import React from 'react';

export default function Cookies() {
  return (
    <div className="py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-gray-900">Cookie Policy</h1>
        <div className="mt-8 prose prose-blue">
          <p className="text-lg text-gray-500">Last updated: {new Date().toLocaleDateString()}</p>

          <h2 className="text-2xl font-bold mt-8">1. What Are Cookies</h2>
          <p>
            Cookies are small text files that are stored on your device when you visit our website.
          </p>

          <h2 className="text-2xl font-bold mt-8">2. How We Use Cookies</h2>
          <p>We use cookies to:</p>
          <ul>
            <li>Remember your preferences</li>
            <li>Understand how you use our website</li>
            <li>Improve our services</li>
            <li>Provide personalized content</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8">3. Types of Cookies We Use</h2>
          <ul>
            <li>Essential cookies</li>
            <li>Performance cookies</li>
            <li>Functionality cookies</li>
            <li>Analytics cookies</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8">4. Managing Cookies</h2>
          <p>
            You can control and manage cookies through your browser settings. Please note that removing or blocking cookies may impact your user experience.
          </p>

          <h2 className="text-2xl font-bold mt-8">5. Contact Us</h2>
          <p>
            If you have questions about our Cookie Policy, please contact us at:
            <br />
            <a href="mailto:privacy@aireceptionist.com" className="text-blue-600 hover:text-blue-800">
              privacy@aireceptionist.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}