import React from 'react';

export default function Privacy() {
  return (
    <div className="py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-gray-900">Privacy Policy</h1>
        <div className="mt-8 prose prose-blue">
          <p className="text-lg text-gray-500">Last updated: {new Date().toLocaleDateString()}</p>
          
          <h2 className="text-2xl font-bold mt-8">1. Information We Collect</h2>
          <p>We collect information that you provide directly to us, including:</p>
          <ul>
            <li>Contact information</li>
            <li>Account credentials</li>
            <li>Communication preferences</li>
            <li>Usage data</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8">2. How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Provide and maintain our services</li>
            <li>Improve and personalize your experience</li>
            <li>Communicate with you</li>
            <li>Ensure security and prevent fraud</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8">3. Data Security</h2>
          <p>
            We implement appropriate technical and organizational measures to maintain the security of your personal information.
          </p>

          <h2 className="text-2xl font-bold mt-8">4. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate data</li>
            <li>Request deletion of your data</li>
            <li>Object to data processing</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8">5. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at:
            <br />
            <a href="mailto:privacy@aireceptionist.com" className="text-blue-600 hover:text-blue-800">
              privacy@aireceptionist.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}