import React from 'react';
import { Database, RefreshCcw, Zap, ArrowRight } from 'lucide-react';

export default function Hubspot() {
  const features = [
    {
      icon: Database,
      title: 'Contact Sync',
      description: 'Bi-directional contact and company information synchronization.'
    },
    {
      icon: RefreshCcw,
      title: 'Real-time Updates',
      description: 'Instant updates to HubSpot records from AI interactions.'
    },
    {
      icon: Zap,
      title: 'Workflow Automation',
      description: 'Trigger HubSpot workflows based on AI interactions.'
    }
  ];

  const capabilities = [
    'Contact management',
    'Deal tracking',
    'Ticket creation',
    'Meeting scheduling',
    'Email integration',
    'Analytics sync'
  ];

  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <img
            src="https://www.hubspot.com/hubfs/HubSpot_Logos/HubSpot-Inversed-Favicon.png"
            alt="HubSpot Logo"
            className="h-16 mx-auto mb-8"
          />
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            HubSpot Integration
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Seamless integration with HubSpot's marketing and sales suite
          </p>
        </div>

        <div className="mt-16 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <div className="mt-6">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <div key={index} className="mt-10 first:mt-0">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-orange-500 text-white">
                          <Icon className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          {feature.title}
                        </h3>
                        <p className="mt-2 text-base text-gray-500">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          
          <div className="mt-10 lg:mt-0">
            <div className="aspect-w-16 aspect-h-9">
              <img
                className="rounded-lg shadow-lg object-cover"
                src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
                alt="HubSpot Integration"
              />
            </div>
          </div>
        </div>

        <div className="mt-16">
          <div className="bg-gray-50 rounded-2xl p-8">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold text-gray-900 text-center">
                Integration Features
              </h2>
              <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2">
                {capabilities.map((capability, index) => (
                  <div
                    key={index}
                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm hover:border-orange-400"
                  >
                    <div className="flex-shrink-0">
                      <Database className="h-6 w-6 text-orange-600" />
                    </div>
                    <div className="min-w-0 flex-1">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900">{capability}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-16">
          <div className="bg-orange-50 rounded-2xl p-8">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">Ready to connect with HubSpot?</h2>
              <p className="mt-4 text-gray-600">
                Integrate your AI receptionist with HubSpot for enhanced marketing and sales.
              </p>
              <div className="mt-8 flex justify-center space-x-4">
                <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-orange-600 hover:bg-orange-700">
                  Start Integration <ArrowRight className="ml-2 h-5 w-5" />
                </button>
                <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-orange-600 bg-white hover:bg-gray-50">
                  View Documentation
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}