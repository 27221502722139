import React from 'react';
import { Search, Book, MessageSquare, FileText, Video, ArrowRight } from 'lucide-react';

const categories = [
  {
    icon: Book,
    title: 'Getting Started',
    description: 'Learn the basics of setting up and using our AI receptionist.',
    articles: [
      'Quick Start Guide',
      'Setting Up Your Account',
      'Basic Configuration',
      'First Call Setup'
    ]
  },
  {
    icon: MessageSquare,
    title: 'Features & Usage',
    description: 'Detailed guides for all features and capabilities.',
    articles: [
      'Call Handling',
      'Message Management',
      'Appointment Scheduling',
      'Integration Setup'
    ]
  },
  {
    icon: FileText,
    title: 'Billing & Account',
    description: 'Information about billing, subscriptions, and account management.',
    articles: [
      'Pricing Plans',
      'Payment Methods',
      'Billing Cycles',
      'Account Settings'
    ]
  },
  {
    icon: Video,
    title: 'Tutorials',
    description: 'Video guides and step-by-step tutorials.',
    articles: [
      'Video Tutorials',
      'Advanced Features',
      'Best Practices',
      'Integration Guides'
    ]
  }
];

const popularArticles = [
  {
    title: 'How to Set Up Your AI Receptionist',
    views: '2.5k views',
    category: 'Getting Started'
  },
  {
    title: 'Configuring Custom Call Scripts',
    views: '1.8k views',
    category: 'Features'
  },
  {
    title: 'Integrating with Your CRM',
    views: '1.5k views',
    category: 'Integration'
  },
  {
    title: 'Understanding Analytics Dashboard',
    views: '1.2k views',
    category: 'Features'
  }
];

export default function Help() {
  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Help Center
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Find answers to your questions about our AI receptionist service
          </p>
        </div>

        {/* Search */}
        <div className="mt-12 max-w-2xl mx-auto">
          <div className="relative">
            <input
              type="text"
              placeholder="Search for help..."
              className="w-full px-4 py-3 pl-12 text-lg border border-gray-300 rounded-full focus:ring-blue-500 focus:border-blue-500"
            />
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          </div>
        </div>

        {/* Categories */}
        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {categories.map((category, index) => {
            const Icon = category.icon;
            return (
              <div key={index} className="bg-white rounded-2xl p-6 shadow-sm border border-gray-200">
                <div className="flex items-center justify-center h-12 w-12 rounded-xl bg-blue-100 text-blue-600 mb-4">
                  <Icon className="h-6 w-6" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">{category.title}</h3>
                <p className="mt-2 text-sm text-gray-500">{category.description}</p>
                <ul className="mt-4 space-y-2">
                  {category.articles.map((article, articleIndex) => (
                    <li key={articleIndex}>
                      <a href="#" className="text-sm text-blue-600 hover:text-blue-700">
                        {article}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>

        {/* Popular Articles */}
        <div className="mt-16">
          <h2 className="text-2xl font-bold text-gray-900">Popular Articles</h2>
          <div className="mt-6 grid gap-6 md:grid-cols-2">
            {popularArticles.map((article, index) => (
              <a
                key={index}
                href="#"
                className="flex items-start p-6 bg-white rounded-xl border border-gray-200 hover:border-blue-500 transition-colors"
              >
                <div className="flex-1">
                  <span className="text-sm font-medium text-blue-600">{article.category}</span>
                  <h3 className="mt-1 text-lg font-medium text-gray-900">{article.title}</h3>
                  <p className="mt-1 text-sm text-gray-500">{article.views}</p>
                </div>
                <ArrowRight className="h-5 w-5 text-gray-400" />
              </a>
            ))}
          </div>
        </div>

        {/* Contact Support */}
        <div className="mt-16">
          <div className="bg-blue-50 rounded-2xl p-8">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">Can't find what you're looking for?</h2>
              <p className="mt-4 text-gray-600">
                Our support team is here to help you with any questions or concerns.
              </p>
              <div className="mt-8 flex justify-center space-x-4">
                <a
                  href="/contact"
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700"
                >
                  Contact Support
                </a>
                <a
                  href="#"
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-blue-600 bg-white hover:bg-gray-50"
                >
                  Schedule a Demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}