import React from 'react';
import { MessageSquare, ThumbsUp, Star, Send } from 'lucide-react';

export default function Feedback() {
  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Share Your Feedback
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Help us improve our AI receptionist service with your valuable feedback
          </p>
        </div>

        {/* Feedback Form */}
        <div className="mt-12 max-w-3xl mx-auto">
          <div className="bg-white rounded-2xl shadow-sm p-8">
            <form className="space-y-6">
              {/* Overall Rating */}
              <div>
                <label className="block text-lg font-medium text-gray-900 mb-4">
                  Overall Experience
                </label>
                <div className="flex space-x-4">
                  {[1, 2, 3, 4, 5].map((rating) => (
                    <button
                      key={rating}
                      type="button"
                      className="p-2 rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <Star className={`h-8 w-8 ${rating <= 3 ? 'text-gray-300' : 'text-yellow-400 fill-current'}`} />
                    </button>
                  ))}
                </div>
              </div>

              {/* Specific Areas */}
              <div>
                <label className="block text-lg font-medium text-gray-900 mb-4">
                  Rate Specific Areas
                </label>
                <div className="space-y-4">
                  {[
                    'AI Response Quality',
                    'Call Handling Speed',
                    'Message Accuracy',
                    'Integration Experience'
                  ].map((area) => (
                    <div key={area} className="flex items-center justify-between">
                      <span className="text-gray-700">{area}</span>
                      <div className="flex space-x-2">
                        {[1, 2, 3, 4, 5].map((rating) => (
                          <button
                            key={rating}
                            type="button"
                            className="p-1 rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            <Star className={`h-5 w-5 ${rating <= 3 ? 'text-gray-300' : 'text-yellow-400 fill-current'}`} />
                          </button>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Detailed Feedback */}
              <div>
                <label htmlFor="feedback" className="block text-lg font-medium text-gray-900 mb-4">
                  Detailed Feedback
                </label>
                <textarea
                  id="feedback"
                  rows={6}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Share your experience and suggestions..."
                ></textarea>
              </div>

              {/* Would Recommend */}
              <div>
                <label className="block text-lg font-medium text-gray-900 mb-4">
                  Would you recommend our service?
                </label>
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="flex items-center px-4 py-2 border rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <ThumbsUp className="h-5 w-5 text-gray-400 mr-2" />
                    Yes
                  </button>
                  <button
                    type="button"
                    className="flex items-center px-4 py-2 border rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <ThumbsUp className="h-5 w-5 text-gray-400 mr-2 rotate-180" />
                    No
                  </button>
                </div>
              </div>

              {/* Contact Information */}
              <div className="space-y-4">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email (optional)
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                    Company (optional)
                  </label>
                  <input
                    type="text"
                    id="company"
                    className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700"
              >
                <Send className="h-5 w-5 mr-2" />
                Submit Feedback
              </button>
            </form>
          </div>
        </div>

        {/* Additional Support */}
        <div className="mt-16">
          <div className="bg-blue-50 rounded-2xl p-8 text-center">
            <h2 className="text-2xl font-bold text-gray-900">Need Additional Support?</h2>
            <p className="mt-4 text-gray-600">
              Our support team is here to help you with any questions or concerns.
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <a
                href="/help"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-blue-600 bg-white hover:bg-gray-50"
              >
                <MessageSquare className="h-5 w-5 mr-2" />
                Visit Help Center
              </a>
              <a
                href="/contact"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700"
              >
                Contact Support
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}