import React from 'react';
import { Phone, Mail, MapPin, Clock } from 'lucide-react';

export default function ContactInfo() {
  return (
    <div className="bg-white rounded-2xl shadow-sm p-8">
      <div className="space-y-8">
        <h3 className="text-2xl font-semibold text-gray-900">Contact Information</h3>
        
        <div className="space-y-6">
          <div className="flex items-start">
            <Phone className="h-6 w-6 text-blue-600 mt-1" />
            <div className="ml-4">
              <p className="text-lg font-medium text-gray-900">Phone</p>
              <p className="text-gray-600">+1 (555) 123-4567</p>
            </div>
          </div>
          
          <div className="flex items-start">
            <Mail className="h-6 w-6 text-blue-600 mt-1" />
            <div className="ml-4">
              <p className="text-lg font-medium text-gray-900">Email</p>
              <p className="text-gray-600">contact@aireceptionist.com</p>
            </div>
          </div>
          
          <div className="flex items-start">
            <MapPin className="h-6 w-6 text-blue-600 mt-1" />
            <div className="ml-4">
              <p className="text-lg font-medium text-gray-900">Office</p>
              <p className="text-gray-600">
                123 Innovation Drive<br />
                Silicon Valley, CA 94025
              </p>
            </div>
          </div>
          
          <div className="flex items-start">
            <Clock className="h-6 w-6 text-blue-600 mt-1" />
            <div className="ml-4">
              <p className="text-lg font-medium text-gray-900">Business Hours</p>
              <p className="text-gray-600">
                Our AI works 24/7<br />
                Human Support: Mon-Fri, 9AM-6PM PST
              </p>
            </div>
          </div>
        </div>

        <div className="border-t pt-8">
          <h4 className="text-lg font-semibold text-gray-900 mb-4">Schedule a Demo</h4>
          <p className="text-gray-600">
            Want to see our AI receptionist in action? Schedule a personalized demo with our team.
          </p>
          <button className="mt-4 w-full bg-blue-50 text-blue-600 px-6 py-3 rounded-full hover:bg-blue-100 transition-colors">
            Book a Demo
          </button>
        </div>
      </div>
    </div>
  );
}