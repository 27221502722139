import React from 'react';
import { Calendar, Download, ArrowRight } from 'lucide-react';

const pressReleases = [
  {
    title: 'AI Receptionist Agency Launches Revolutionary Communication Platform',
    date: 'March 15, 2024',
    excerpt: 'Introducing a new era of business communication with advanced AI technology.',
    link: '#'
  },
  {
    title: 'New Multi-language Support Added to AI Reception Services',
    date: 'March 10, 2024',
    excerpt: 'Expanding global reach with support for multiple languages.',
    link: '#'
  },
  {
    title: 'AI Receptionist Agency Partners with Leading CRM Providers',
    date: 'March 5, 2024',
    excerpt: 'Strategic partnerships to enhance integration capabilities.',
    link: '#'
  }
];

const mediaKit = [
  {
    title: 'Company Logo Pack',
    format: 'ZIP',
    size: '2.5 MB',
    link: '#'
  },
  {
    title: 'Brand Guidelines',
    format: 'PDF',
    size: '1.8 MB',
    link: '#'
  },
  {
    title: 'Product Screenshots',
    format: 'ZIP',
    size: '5.2 MB',
    link: '#'
  }
];

export default function Press() {
  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Press & Media
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Latest news and media resources about AI Receptionist Agency
          </p>
        </div>

        {/* Press Contact */}
        <div className="mt-12 bg-blue-50 rounded-2xl p-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Press Contact</h2>
            <p className="mt-4 text-gray-600">
              For press inquiries, please contact our media relations team
            </p>
            <div className="mt-6">
              <a
                href="mailto:press@aireceptionist.com"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700"
              >
                Contact Press Team
              </a>
            </div>
          </div>
        </div>

        {/* Press Releases */}
        <div className="mt-16">
          <h2 className="text-3xl font-bold text-gray-900">Press Releases</h2>
          <div className="mt-8 space-y-8">
            {pressReleases.map((release, index) => (
              <div
                key={index}
                className="bg-white rounded-2xl p-6 shadow-sm border border-gray-200"
              >
                <div className="flex items-center text-sm text-gray-500">
                  <Calendar className="h-5 w-5 mr-2" />
                  {release.date}
                </div>
                <h3 className="mt-2 text-xl font-bold text-gray-900">
                  {release.title}
                </h3>
                <p className="mt-3 text-gray-600">{release.excerpt}</p>
                <a
                  href={release.link}
                  className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-700"
                >
                  Read full release <ArrowRight className="ml-2 h-4 w-4" />
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* Media Kit */}
        <div className="mt-16">
          <h2 className="text-3xl font-bold text-gray-900">Media Kit</h2>
          <div className="mt-8 grid gap-6 md:grid-cols-3">
            {mediaKit.map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-2xl p-6 shadow-sm border border-gray-200"
              >
                <h3 className="text-lg font-semibold text-gray-900">{item.title}</h3>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <span>{item.format}</span>
                  <span className="mx-2">·</span>
                  <span>{item.size}</span>
                </div>
                <a
                  href={item.link}
                  className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-700"
                >
                  <Download className="h-5 w-5 mr-2" />
                  Download
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* Coverage */}
        <div className="mt-16">
          <h2 className="text-3xl font-bold text-gray-900">Featured In</h2>
          <div className="mt-8 grid grid-cols-2 gap-8 md:grid-cols-4">
            <div className="flex justify-center items-center grayscale hover:grayscale-0 transition-all">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/2880px-Google_2015_logo.svg.png"
                alt="Google"
                className="h-12"
              />
            </div>
            <div className="flex justify-center items-center grayscale hover:grayscale-0 transition-all">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/TechCrunch_logo.svg/2560px-TechCrunch_logo.svg.png"
                alt="TechCrunch"
                className="h-12"
              />
            </div>
            <div className="flex justify-center items-center grayscale hover:grayscale-0 transition-all">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Forbes_logo.svg/2560px-Forbes_logo.svg.png"
                alt="Forbes"
                className="h-12"
              />
            </div>
            <div className="flex justify-center items-center grayscale hover:grayscale-0 transition-all">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Wired_logo.svg/2560px-Wired_logo.svg.png"
                alt="Wired"
                className="h-12"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}