import React from 'react';

export default function GDPR() {
  return (
    <div className="py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-gray-900">GDPR Compliance</h1>
        <div className="mt-8 prose prose-blue">
          <p className="text-lg text-gray-500">Last updated: {new Date().toLocaleDateString()}</p>

          <h2 className="text-2xl font-bold mt-8">1. Data Protection Rights</h2>
          <p>Under GDPR, you have the following rights:</p>
          <ul>
            <li>Right to access your data</li>
            <li>Right to rectification</li>
            <li>Right to erasure</li>
            <li>Right to restrict processing</li>
            <li>Right to data portability</li>
            <li>Right to object</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8">2. Data Processing</h2>
          <p>
            We process personal data in accordance with GDPR principles:
          </p>
          <ul>
            <li>Lawfulness, fairness, and transparency</li>
            <li>Purpose limitation</li>
            <li>Data minimization</li>
            <li>Accuracy</li>
            <li>Storage limitation</li>
            <li>Integrity and confidentiality</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8">3. International Transfers</h2>
          <p>
            We ensure appropriate safeguards for data transfers outside the EEA.
          </p>

          <h2 className="text-2xl font-bold mt-8">4. Data Protection Officer</h2>
          <p>
            For GDPR-related inquiries, contact our Data Protection Officer at:
            <br />
            <a href="mailto:dpo@aireceptionist.com" className="text-blue-600 hover:text-blue-800">
              dpo@aireceptionist.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}