import React from 'react';
import { Stethoscope, Shield, Clock, FileText, ArrowRight } from 'lucide-react';

export default function Medical() {
  const features = [
    {
      icon: Shield,
      title: 'HIPAA Compliant',
      description: 'Secure, compliant handling of patient information and medical communications.'
    },
    {
      icon: Clock,
      title: 'Emergency Triage',
      description: '24/7 emergency call handling with smart prioritization protocols.'
    },
    {
      icon: Stethoscope,
      title: 'Patient Care',
      description: 'Compassionate patient interaction with medical-specific protocols.'
    }
  ];

  const capabilities = [
    'Appointment scheduling',
    'Insurance verification',
    'Patient intake',
    'Emergency routing',
    'Follow-up coordination',
    'Prescription refill handling'
  ];

  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Healthcare Solutions
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            HIPAA-compliant AI reception services for medical practices
          </p>
        </div>

        <div className="mt-16 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <div className="mt-6">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <div key={index} className="mt-10 first:mt-0">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                          <Icon className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          {feature.title}
                        </h3>
                        <p className="mt-2 text-base text-gray-500">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          
          <div className="mt-10 lg:mt-0">
            <div className="aspect-w-16 aspect-h-9">
              <img
                className="rounded-lg shadow-lg object-cover"
                src="https://images.unsplash.com/photo-1579684385127-1ef15d508118?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
                alt="Healthcare Practice"
              />
            </div>
          </div>
        </div>

        <div className="mt-16">
          <div className="bg-gray-50 rounded-2xl p-8">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold text-gray-900 text-center">
                Healthcare Capabilities
              </h2>
              <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2">
                {capabilities.map((capability, index) => (
                  <div
                    key={index}
                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm hover:border-blue-400"
                  >
                    <div className="flex-shrink-0">
                      <FileText className="h-6 w-6 text-blue-600" />
                    </div>
                    <div className="min-w-0 flex-1">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900">{capability}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-16">
          <div className="bg-blue-50 rounded-2xl p-8">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">Ready to enhance patient care?</h2>
              <p className="mt-4 text-gray-600">
                Let our HIPAA-compliant AI handle patient communications while you focus on care.
              </p>
              <div className="mt-8 flex justify-center space-x-4">
                <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700">
                  Start Free Trial <ArrowRight className="ml-2 h-5 w-5" />
                </button>
                <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-blue-600 bg-white hover:bg-gray-50">
                  Schedule Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}