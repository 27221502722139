import React from 'react';
import { Check, X } from 'lucide-react';

export default function Compare() {
  const plans = [
    {
      name: 'Basic',
      price: 99,
      description: 'For small businesses',
      features: {
        'Monthly calls': '100',
        'Business hours coverage': true,
        'Basic call screening': true,
        'Message taking': true,
        'Email notifications': true,
        'Basic reporting': true,
        '24/7 coverage': false,
        'Custom greeting': false,
        'CRM integration': false,
        'Multi-language support': false,
        'Priority support': false,
        'Custom AI training': false,
        'API access': false,
        'Advanced analytics': false,
        'Custom integrations': false,
        'Dedicated account manager': false
      }
    },
    {
      name: 'Professional',
      price: 199,
      popular: true,
      description: 'For growing businesses',
      features: {
        'Monthly calls': '500',
        'Business hours coverage': true,
        'Basic call screening': true,
        'Message taking': true,
        'Email notifications': true,
        'Basic reporting': true,
        '24/7 coverage': true,
        'Custom greeting': true,
        'CRM integration': true,
        'Multi-language support': true,
        'Priority support': false,
        'Custom AI training': false,
        'API access': false,
        'Advanced analytics': true,
        'Custom integrations': false,
        'Dedicated account manager': false
      }
    },
    {
      name: 'Enterprise',
      price: 'Custom',
      description: 'For large organizations',
      features: {
        'Monthly calls': 'Unlimited',
        'Business hours coverage': true,
        'Basic call screening': true,
        'Message taking': true,
        'Email notifications': true,
        'Basic reporting': true,
        '24/7 coverage': true,
        'Custom greeting': true,
        'CRM integration': true,
        'Multi-language support': true,
        'Priority support': true,
        'Custom AI training': true,
        'API access': true,
        'Advanced analytics': true,
        'Custom integrations': true,
        'Dedicated account manager': true
      }
    }
  ];

  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            Compare Plans
          </h1>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Find the perfect plan for your business needs
          </p>
        </div>

        <div className="mt-16 overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-t border-gray-200">
                <th className="py-5 px-4 text-left text-sm font-semibold text-gray-900 w-1/4">
                  Features
                </th>
                {plans.map((plan, index) => (
                  <th key={index} className="px-4 text-center">
                    <div className={`pb-4 ${plan.popular ? 'relative' : ''}`}>
                      {plan.popular && (
                        <div className="absolute -top-4 left-0 right-0">
                          <span className="inline-flex rounded-full bg-blue-600 px-4 py-1 text-sm font-semibold text-white">
                            Most Popular
                          </span>
                        </div>
                      )}
                      <div className="text-lg font-semibold text-gray-900">{plan.name}</div>
                      <div className="mt-2 text-sm text-gray-500">{plan.description}</div>
                      <div className="mt-2">
                        <span className="text-3xl font-bold text-gray-900">
                          {typeof plan.price === 'number' ? `$${plan.price}` : plan.price}
                        </span>
                        {typeof plan.price === 'number' && (
                          <span className="text-gray-500">/mo</span>
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="border-t border-gray-200 divide-y divide-gray-200">
              {Object.keys(plans[0].features).map((feature, index) => (
                <tr key={index}>
                  <td className="py-5 px-4 text-sm font-medium text-gray-900">
                    {feature}
                  </td>
                  {plans.map((plan, planIndex) => (
                    <td key={planIndex} className="px-4 text-center">
                      {typeof plan.features[feature] === 'boolean' ? (
                        plan.features[feature] ? (
                          <Check className="mx-auto h-5 w-5 text-blue-600" />
                        ) : (
                          <X className="mx-auto h-5 w-5 text-gray-300" />
                        )
                      ) : (
                        <span className="text-sm text-gray-900">{plan.features[feature]}</span>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-16">
          <div className="bg-blue-50 rounded-2xl p-8 text-center">
            <h2 className="text-2xl font-bold text-gray-900">Need help choosing?</h2>
            <p className="mt-4 text-gray-600">
              Our team is here to help you find the perfect plan for your business.
            </p>
            <div className="mt-8">
              <a
                href="/contact"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700"
              >
                Contact Sales
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}